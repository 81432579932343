export const skillsData = [
    'Go',
    'Python',
    'Docker',
    'Javascript',
    'Typescript',
    'React',
    'nodejs',
    'cypress',
    'ViteJS',
    'jenkins',
    'groovy',
    'bash',
    'postman',
    'prometheus',
    'Angular',
    'Ionic',
    'PostgreSQL',
    'Firebase',
    'HTML',
    'CSS',
    'Java',
    'Selenium',
    'AWS',
    'Git',
    'MongoDB',
    'grafana',
    'jira',
    'kubernetes',
    'mocha',
    'nightwatch',
    'winston',
    'Swift',
    'Kotlin',
]
