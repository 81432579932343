import p from '../assets/png/p.png'

export const landingImage = (c) => {
    const cID = c.toLowerCase();
    switch (cID) {
        case 'p':
            return p;
        default:
            break;
    }
}
